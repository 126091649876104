import React, { useState, Fragment } from "react";
import Papa from "papaparse";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";

import { useSubmitForm } from "../hooks/useSubmitForm";
import { useDialog } from "../hooks/useDialog";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const CSVReader = () => {
  const classes = useStyles();
  const [csvfile, setCsvfile] = useState();
  const [payload, setPayload] = useState();
  const [isCheckConfirmed, setIsCheckConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const userState = useSelector((state) => state.user);
  const { MyDialog, createDialog } = useDialog("useinfoupdate");
  const { masterSubmitForm } = useSubmitForm(userState.token);

  const handleChange = (event) => {
    setCsvfile(event.target.files[0]);
  };

  const handleResults = (results) => {
    let dataArray = [];

    console.log(results);

    results.data.map((item, index) => {
      let tempObject;

      let classnameArray = [];
      let classidArray = [];
      let testClassArray = [];
      let allemailArray = [];
      let allemailObjectArray = [];

      if (item.schoolid) {
        allemailArray = item.email.split(",");

        for (let i = 0; i < allemailArray.length; i++) {
          allemailObjectArray.push({
            emailaddress: allemailArray[i],
          });
        }

        classnameArray = item.SampledClass.split(",");
        classidArray = item.classID.split(",");

        for (let i = 0; i < classnameArray.length; i++) {
          testClassArray.push({
            classname: classnameArray[i],
            classid: classidArray[i],
          });
        }

        tempObject = {
          username: item.schoolid,
          email: allemailArray[0],
          password: allemailArray[0], //will be hashed automatically
          confirmed: true,
          schoolId: item.schoolid,
          chineseaddress: item.address.trim(),
          schoolName: item.chineseSchoolName.trim(),
          schooltel: item.schooltel.trim(),
          contacttel: item.tel.trim(),
          contactperson: item.contactperson.trim(),
          allemail: allemailObjectArray,
          testclass: testClassArray,
        };
        return dataArray.push(tempObject);
      }
      return false;
    });

    setPayload(dataArray);
  };

  const handleCreateSingleUser = (payload) => {
    masterSubmitForm("users", payload, createDialog);
  };

  const handleCreateUsers = () => {
    setErrorMessage("");
    if (!isCheckConfirmed) {
      setErrorMessage("請確認資料");
      return;
    }
    payload.map((item) => {
      return handleCreateSingleUser(item);
    });
  };

  const importCSV = () => {
    console.log("clicked");
    Papa.parse(csvfile, {
      complete: function (results) {
        handleResults(results);
      },
      header: true,
    });
  };

  console.log(payload);

  if (!userState.isLoggedIn || !userState.userType === "CACLER") {
    return <Redirect to={"/"} />;
  }

  return (
    <Fragment>
      {MyDialog}
      <h2>Import CSV File!</h2>
      <input
        className="csv-input"
        type="file"
        name="file"
        placeholder={null}
        onChange={handleChange}
      />
      <p />
      <button
        onClick={() => {
          importCSV();
        }}
      >
        {" "}
        Upload now!
      </button>
      {payload && (
        <Fragment>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/*}
                  <TableCell>ID (just for counting)</TableCell>
                  <TableCell align="right">username</TableCell>
                  <TableCell align="right">email</TableCell>*/}
                  <TableCell align="right">password</TableCell>
                  <TableCell align="right">schoolId</TableCell>
                  <TableCell align="right">chineseaddress</TableCell>
                  <TableCell align="right">schoolName</TableCell>
                  <TableCell align="right">schooltel</TableCell>
                  <TableCell align="right">contacttel</TableCell>
                  <TableCell align="right">contactperson</TableCell>
                  <TableCell align="right">allemail</TableCell>
                  <TableCell align="right">testclass</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payload.map((row, index) => (
                  <TableRow key={index}>
                    {/*<TableCell component="th" scope="row">
                      {index}
                </TableCell>
                    <TableCell align="right">{row.username}</TableCell>
                    <TableCell align="right">{row.email}</TableCell>*/}
                    <TableCell align="right">{row.password}</TableCell>
                    <TableCell align="right">{row.schoolId}</TableCell>
                    <TableCell align="right">{row.chineseaddress}</TableCell>
                    <TableCell align="right">{row.schoolName}</TableCell>
                    <TableCell align="right">{row.schooltel}</TableCell>
                    <TableCell align="right">{row.contacttel}</TableCell>
                    <TableCell align="right">{row.contactperson}</TableCell>
                    <TableCell align="right">
                      {JSON.stringify(row.allemail)}
                    </TableCell>
                    <TableCell align="right">
                      {JSON.stringify(row.testclass)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckConfirmed}
                onChange={() => {
                  setIsCheckConfirmed(!isCheckConfirmed);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
                required
              />
            }
            label="請確認資料"
          />
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateUsers}
          >
            Upload to Server
          </Button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CSVReader;
