const defaultState = {
  isLoggedIn: false,
  userType: "",
  userId: "",
  email: "",
  token: "",
  schoolName: "",
  schoolId: "",
  testclass: [],
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SIGN_IN_SUCCESS":
      state = {
        isLoggedIn: true,
        userType: action.payload.userType,
        userId: action.payload.userId,
        email: action.payload.email,
        token: action.payload.token,
        schoolName: action.payload.schoolname,
        schoolId: action.payload.schoolId,
        testclass: action.payload.testclass,
      };
      return state;
    case "SIGN_OUT":
      state = defaultState;
      return state;
    default:
      return state;
  }
};

export default userReducer;
