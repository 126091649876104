import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const MyDatePicker = (props) => {
  const { blockDatesArray, selectedDate, setSelectedDate } = props;

  const checkIsBlocked = (todayFullDate) => {
    if (blockDatesArray.includes(todayFullDate)) {
      console.log("matched");
      return true;
    }
    return false;
  };

  const disableDates = (date) => {
    let todayFullDate;
    let month = date.getMonth() + 1; //months from 1-12
    if (month < 10) {
      month = month.toString().padStart(2, "0");
    }

    let day = date.getDate();
    if (day < 10) {
      day = day.toString().padStart(2, "0");
    }

    let year = date.getFullYear();

    todayFullDate = year + "-" + month + "-" + day;

    console.log(todayFullDate);

    /*
      date.getDay() === 0 ||
      date.getDay() === 6 ||
      checkIsBlocked(todayFullDate)
    */

    return (
      date.getDay() === 0 ||
      checkIsBlocked(todayFullDate)
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        value={selectedDate}
        onChange={setSelectedDate}
        format="dd/MM/yyyy"
        disablePast={true}
        shouldDisableDate={disableDates}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MyDatePicker;
