import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  spaceLeft: {
    paddingLeft: theme.spacing(1),
  },
}));

const ReserveDate = (props) => {
  const { payload, statusChinese } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <Fragment>
      <Typography paragraph>中文科教師姓名: {payload.teacherName}</Typography>
      <Typography paragraph>班别: {payload.targetedClassName}</Typography>
      <Typography paragraph>學生人數: {payload.numberOfStudent}</Typography>
      <Typography paragraph>
        預定測試日期及時間: {payload.selectedTestDate} {payload.testStartTime} -{" "}
        {payload.testEndTime}
      </Typography>
      <Typography paragraph>
        學生問卷的進行日期及時間: {payload.selectedSurveyDate}{" "}
        {payload.surveyStartTime} - {payload.surveyEndTime}
      </Typography>
      <Typography paragraph>狀態: {statusChinese}</Typography>

      {payload.fieldstatus !== "updated" && (
        <Fragment>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/form/testdate/update/${payload.id}`);
            }}
          >
            更改
          </Button>

          <Typography
            component="p"
            display="inline"
            className={classes.spaceLeft}
          >
            <Link
              href="https://bit.ly/2Ppxhs2"
              color="inherit"
              target="_blank"
              rel="noopener"
            >
              {"(使用說明 影片 / 長度：2:06 / 大小：7.9MB)"}
            </Link>
          </Typography>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ReserveDate;
