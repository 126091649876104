import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MyDatePicker from "../MyDatePicker";
import Alert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";

import { useSelector } from "react-redux";
import { useDatePicker } from "../../hooks/useDatepicker";
import { useSchoolData } from "../../hooks/useSchoolData";

import MyLoadingSpinner from "../MyLoadingSpinner";
import TestDateHeader from "../NoticeBoard/TestDateHeader";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  innerPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paddingTop: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3),
  },
}));

const initialFormValues = {
  teacherName: "",
  targetedClassName: "",
  numberOfStudent: "",
  testStartTime: "",
  testEndTime: "",
  surveyStartTime: "",
  surveyEndTime: "",
};

const DateFrom = (props) => {
  const classes = useStyles();

  const { containerSubmitForm, payload, schoolData, setIsLoading } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [formValues, setFormValues] = useState(initialFormValues);
  const [isCheckConfirmed, setIsCheckConfirmed] = useState(false);
  const [isSurveySameDate, setIsSurveySameDate] = useState(true);
  const { method, targetedId } = useParams();

  const [isSubmitDisable, setIsSubmitDisable] = useState(false);

  const [targetedClass, setTargetedClass] = useState(false);

  const userState = useSelector((state) => state.user);
  const { activeReserveDateData } = useSchoolData(userState.token, "testdate");

  const { blockDatesArray, dateIsLoading } = useDatePicker(userState.token);

  useEffect(() => {
    if (payload) {
      setFormValues(payload);
      setSelectedDate(payload.selectedTestDate);
      setSelectedDate2(payload.selectedSurveyDate);
      setIsSurveySameDate(false);
      setTargetedClass(payload.internalid);
    }
  }, [payload]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const submitForm = () => {
    setErrorMessage(null);
    if (!isCheckConfirmed) {
      setErrorMessage("請確認資料");
      return;
    }

    if (formValues.teachername === "" || formValues.numberOfStudent === "") {
      setErrorMessage("請填寫資料");
      return;
    }

    let submitFormValues = {};
    if (isSurveySameDate) {
      submitFormValues = {
        ...formValues,
        selectedTestDate: selectedDate,
        selectedSurveyDate: selectedDate,
        internalid: targetedClass,
        targetedClassName: targetUser.testclass[targetedClass].classname,
      };
    } else {
      submitFormValues = {
        ...formValues,
        selectedTestDate: selectedDate,
        selectedSurveyDate: selectedDate2,
        internalid: targetedClass,
        targetedClassName: targetUser.testclass[targetedClass].classname,
      };
    }
    setIsSubmitDisable(true);
    containerSubmitForm(submitFormValues, "reserve-test-dates");
  };

  const chooseForm = (index) => {
    setTargetedClass(index);
  };

  let targetUser;
  if (method === "update") {
    targetUser = payload.users_permissions_user;
  } else {
    if (targetedId === "personal") {
      targetUser = userState;
    } else {
      targetUser = schoolData;
    }
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        {targetedClass === false && (
          <TestDateHeader
            targetUser={targetUser}
            activeReserveDateData={activeReserveDateData}
            setIsLoading={setIsLoading}
            chooseForm={chooseForm}
          />
        )}
      </Grid>
      {targetedClass !== false && (
        <Fragment>
          {dateIsLoading ? (
            <MyLoadingSpinner />
          ) : (
            <Fragment>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  班别: {targetUser.testclass[targetedClass].classname}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="teacherName"
                  name="teacherName"
                  label="中文科教師姓名"
                  fullWidth
                  autoComplete="teacher-name"
                  value={formValues.teacherName}
                  onChange={handleInputChange}
                  error={!!(errorMessage && formValues.teacherName === "")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="numberOfStudent"
                  name="numberOfStudent"
                  label="學生人數"
                  fullWidth
                  value={formValues.numberOfStudent}
                  onChange={handleInputChange}
                  error={!!(errorMessage && formValues.numberOfStudent === "")}
                />
              </Grid>

              <Paper className={classes.innerPaper} elevation={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      預定測試日期：
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      (請儘量安排在2021年4月12日至7月15日之內，連同準備時間，整個測試需時約100分鐘，測試分兩部份，每部份40分鐘，中間可給予學生5-10分鐘小休)
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <MyDatePicker
                      blockDatesArray={blockDatesArray}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="testStartTime"
                      name="testStartTime"
                      label="開始時間"
                      fullWidth
                      value={formValues.testStartTime}
                      onChange={handleInputChange}
                      error={
                        !!(errorMessage && formValues.testStartTime === "")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="testEndTime"
                      name="testEndTime"
                      label="完結時間"
                      fullWidth
                      value={formValues.testEndTime}
                      onChange={handleInputChange}
                      error={!!(errorMessage && formValues.testEndTime === "")}
                    />
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom>
                      學生問卷的進行日期及時間:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSurveySameDate}
                          color="primary"
                          onChange={() => {
                            setIsSurveySameDate(!isSurveySameDate);
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          required
                        />
                      }
                      label="同日進行"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom>
                      (建議於完成測驗當天處理《學生問卷》，如未能處理，請於測驗後另選日期，《學生問卷》需時約40分鐘。)
                    </Typography>
                  </Grid>
                  {!isSurveySameDate && (
                    <Grid item xs={12}>
                      <MyDatePicker
                        blockDatesArray={blockDatesArray}
                        selectedDate={selectedDate2}
                        setSelectedDate={setSelectedDate2}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="surveyStartTime"
                      name="surveyStartTime"
                      label="開始時間"
                      fullWidth
                      value={formValues.surveyStartTime}
                      onChange={handleInputChange}
                      error={
                        !!(errorMessage && formValues.surveyStartTime === "")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="surveyEndTime"
                      name="surveyEndTime"
                      label="完結時間"
                      fullWidth
                      value={formValues.surveyEndTime}
                      onChange={handleInputChange}
                      error={
                        !!(errorMessage && formValues.surveyEndTime === "")
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCheckConfirmed}
                      onChange={() => {
                        setIsCheckConfirmed(!isCheckConfirmed);
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      required
                    />
                  }
                  label="請確認資料"
                />
              </Grid>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitForm}
                  className={classes.button}
                  disabled={isSubmitDisable}
                >
                  提交
                </Button>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DateFrom;
