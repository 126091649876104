import { Fragment } from "react";

import Typography from "@material-ui/core/Typography";

const TestDate = (props) => {
  const { payload } = props;

  return (
    <Fragment>
      <Typography component="h1" variant="h4" align="center">
        測試日期: {payload.selectedTestDate}
      </Typography>
      <Typography paragraph>中文科教師姓名: {payload.teacherName}</Typography>
      <Typography paragraph>班别: {payload.targetedClassName}</Typography>
      <Typography paragraph>學生人數: {payload.numberOfStudent}</Typography>
      <Typography paragraph>
        預定測試日期及時間: {payload.selectedTestDate} ({payload.testStartTime}{" "}
        - {payload.testEndTime})
      </Typography>
      <Typography paragraph>
        學生問卷的進行日期及時間: {payload.selectedSurveyDate} (
        {payload.surveyStartTime} - {payload.surveyEndTime})
      </Typography>
      <Typography paragraph>
        狀態: {payload.status === "Pending" && "等待確認"}
        {payload.status === "Confirmed" && "已確認"}
        {payload.status === "Cancelled" && "已取消"}
      </Typography>
    </Fragment>
  );
};

export default TestDate;
