import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Button from "@material-ui/core/Button";

import MyLoadingSpinner from "../components/MyLoadingSpinner";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ChosenDateList() {
  const userState = useSelector((state) => state.user);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [schoolsData, setSchoolsData] = useState([]);

  useEffect(() => {
    getUsers(userState.token);
  }, [userState.token]);

  const getUsers = (token) => {
    let tempArray = [];
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/reserve-test-dates?_limit=-1`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          tempArray.push({
            id: response.data[i].id,
            status: response.data[i].status,
            fieldstatus: response.data[i].fieldstatus,
            school: response.data[i].users_permissions_user.username,
            schoolname: response.data[i].users_permissions_user.schoolName,
            testdate: response.data[i].selectedTestDate,
            surveydate: response.data[i].selectedSurveyDate,
            classname: response.data[i].targetedClassName,
          });
        }
        setSchoolsData(tempArray);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error.
        setIsLoading(false);
        console.log("An error occurred:", error.response);
      });
  };

  const refreshTable = () => {
    getUsers(userState.token);
  };

  const columns = [
    {
      title: "id",
      field: "id",
    },
    {
      title: "status",
      field: "status",
    },
    {
      title: "更新 status",
      field: "fieldstatus",
    },
    {
      title: "schoolid",
      field: "school",
    },
    {
      title: "schoolname",
      field: "schoolname",
    },
    {
      title: "classname",
      field: "classname",
    },
    {
      title: "testdate",
      field: "testdate",
    },
    {
      title: "surveydate",
      field: "surveydate",
    },
    {
      title: "edit",
      field: "url",
      render: (rowData) => {
        if (rowData.fieldstatus === "updated") {
          return <p>N/A</p>;
        } else {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/form/testdate/update/${rowData.id}`);
              }}
            >
              Edit
            </Button>
          );
        }
      },
    },
    {
      title: "updatestatus",
      field: "url",
      render: (rowData) => {
        if (rowData.fieldstatus === "updated") {
          return <p>N/A</p>;
        } else {
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/statusupdate/testdate/${rowData.id}`);
              }}
            >
              Update Status
            </Button>
          );
        }
      },
    },
  ];
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          refreshTable();
        }}
      >
        Refresh
      </Button>
      {isLoading ? (
        <MyLoadingSpinner />
      ) : (
        <React.Fragment>
          <div style={{ maxwidth: "100%" }}>
            <MaterialTable
              icons={tableIcons}
              title="Chosen Date List (Set '更新狀態' = 'active' to filter out updated record)"
              data={schoolsData}
              columns={columns}
              options={{
                search: true,
                paging: false,
                filtering: true,
                exportButton: true,
              }}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
