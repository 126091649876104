import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const ClassInfoDialog = (props) => {
  const {
    isDialogOpen,
    handleClose,
    formValues,
    classIndex,
    setFormValues,
    setClassIndex,
  } = props;

  const [emailaddress, setEmailaddress] = useState("");

  const handleUpdate = () => {
    if (classIndex === "addnew") {
      let tempValues = {
        emailaddress: emailaddress,
      };
      let tempAllValues = formValues.allemail;
      tempAllValues.push(tempValues);
      setFormValues({ ...formValues, allemail: tempAllValues });
      handleClose();
    } else {
      let tempValues = {
        emailaddress: emailaddress,
      };
      let tempAllValues = formValues.allemail;
      tempAllValues[classIndex] = tempValues;
      setFormValues({ ...formValues, allemail: tempAllValues });
      handleClose();
    }
  };

  const handleDelete = () => {
    let tempAllValues = formValues.allemail;
    tempAllValues.splice(classIndex, 1);
    setFormValues({ ...formValues, allemail: tempAllValues });
    setClassIndex(0);
    handleClose();
  };

  useEffect(() => {
    if (classIndex !== "addnew") {
      if (formValues.allemail) {
        setEmailaddress(formValues.allemail[classIndex].emailaddress);
      }
    } else {
      setEmailaddress("");
    }
  }, [classIndex, formValues.allemail]);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Edit Class ID: ({classIndex})
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="emailaddress"
          label="Email Address"
          type="email"
          value={emailaddress}
          fullWidth
          onChange={(e) => {
            setEmailaddress(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary">
          Delete
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClassInfoDialog;
