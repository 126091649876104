import React, { Fragment } from "react";

import processString from "react-process-string";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const config = [
  {
    regex: /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |$|\.)/gim,
    fn: (key, result) => (
      <span key={key}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${result[1]}://${result[2]}.${result[3]}${result[4]}`}
        >
          {result[2]}.{result[3]}
          {result[4]}
        </a>
        {result[5]}
      </span>
    ),
  },
  {
    regex: /(\S+)\.([a-z]{2,}?)(.*?)( |$|\.)/gim,
    fn: (key, result) => (
      <span key={key}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`http://${result[1]}.${result[2]}${result[3]}`}
        >
          {result[1]}.{result[2]}
          {result[3]}
        </a>
        {result[4]}
      </span>
    ),
  },
];

const Message = (props) => {
  const { payload } = props;
  const classes = useStyles();

  return (
    <Fragment>
      {payload.message.split("\n").map((str) => (
        <Typography paragraph>{processString(config)(str)}</Typography>
      ))}
      {payload.uploadfiles.map((item) => {
        return (
          <Fragment>
            <Button
              className={classes.button}
              variant="contained"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_BACKEND_DOMAIN}${item.url}`,
                  "_blank"
                )
              }
            >
              <FileCopyIcon /> {item.name}
            </Button>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default Message;
