import React, { Fragment, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import MyLoadingSpinner from "../components/MyLoadingSpinner";

import { useTargetSchoolData } from "../hooks/useTargetSchoolData";
import ClassInfoDialog from "../components/UserInfo/ClassInfoDialog";
import EmailInfoDialog from "../components/UserInfo/EmailInfoDialog";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import { useSubmitForm } from "../hooks/useSubmitForm";
import { useDialog } from "../hooks/useDialog";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  innerPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paddingX: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const initialFormValues = {
  schoolName: "",
  chineseaddress: "",
  contactperson: "",
  schooltel: "",
};

const UserInfo = () => {
  const userState = useSelector((state) => state.user);
  let { targetedId } = useParams();
  const classes = useStyles();

  const [formValues, setFormValues] = useState(initialFormValues);
  const [isCheckConfirmed, setIsCheckConfirmed] = useState(false);
  const [isClassDialogOpen, setIsClassDialogOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [classIndex, setClassIndex] = useState("addnew");
  const [emailIndex, setEmailIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const { MyDialog, createDialog } = useDialog("useinfoupdate");

  const { masterSubmitStatusUpdate } = useSubmitForm(userState.token);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleBlockedChange = (value) => {
    setFormValues({
      ...formValues,
      blocked: value,
    });
  };

  const submitForm = () => {
    setErrorMessage("");
    if (!isCheckConfirmed) {
      setErrorMessage("請確認資料");
      return;
    }
    if (targetedId && isCheckConfirmed) {
      let submitFormValues = { ...formValues };
      masterSubmitStatusUpdate(
        "users",
        targetedId,
        submitFormValues,
        createDialog
      );
    }
  };

  const { schoolData, isLoading: schoolDataIsLoading } = useTargetSchoolData(
    userState.token,
    targetedId
  );

  useEffect(() => {
    if (schoolData) {
      setFormValues(schoolData);
    }
  }, [schoolData]);

  if (!userState.isLoggedIn || !userState.userType === "CACLER") {
    return <Redirect to={"/"} />;
  }

  const handleClassUpdate = (index) => {
    setClassIndex(index);
    setIsClassDialogOpen(true);
  };

  const handleEmailUpdate = (index) => {
    setEmailIndex(index);
    setIsEmailDialogOpen(true);
  };

  const handleClassClose = () => {
    setIsClassDialogOpen(false);
  };

  const handleEmailClose = () => {
    setIsEmailDialogOpen(false);
  };

  return (
    <Fragment>
      {MyDialog}
      {schoolDataIsLoading ? (
        <MyLoadingSpinner />
      ) : (
        <Fragment>
          <ClassInfoDialog
            isDialogOpen={isClassDialogOpen}
            handleClose={handleClassClose}
            formValues={formValues}
            classIndex={classIndex}
            setFormValues={setFormValues}
          />
          <EmailInfoDialog
            isDialogOpen={isEmailDialogOpen}
            handleClose={handleEmailClose}
            formValues={formValues}
            classIndex={emailIndex}
            setFormValues={setFormValues}
            setClassIndex={setClassIndex}
          />
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              School Info Settings
            </Typography>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                schoolID: {schoolData.username}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                defaultemail: {schoolData.email}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formValues.blocked}
                    onChange={() => {
                      handleBlockedChange(!formValues.blocked);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    required
                  />
                }
                label="Blocked User (Disable account)"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Emails: *** At least 1 email is required (don't delete all) ***
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleEmailUpdate("addnew");
                }}
              >
                Add New
              </Button>
              {formValues.allemail &&
                formValues.allemail.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <Paper className={classes.innerPaper}>
                        <Typography variant="subtitle1" gutterBottom>
                          email address: {item.emailaddress}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleEmailUpdate(index);
                          }}
                        >
                          Edit
                        </Button>
                      </Paper>
                    </Fragment>
                  );
                })}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Classes:{" "}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleClassUpdate("addnew");
                }}
              >
                Add New
              </Button>
              {formValues.testclass &&
                formValues.testclass.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <Paper className={classes.innerPaper}>
                        <Typography variant="subtitle1" gutterBottom>
                          class id: {item.classid}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          class name: {item.classname}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleClassUpdate(index);
                          }}
                        >
                          Edit
                        </Button>
                      </Paper>
                    </Fragment>
                  );
                })}
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="schoolName"
                name="schoolName"
                label="schoolName"
                fullWidth
                value={formValues.schoolName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="schooltel"
                name="schooltel"
                label="schooltel"
                fullWidth
                value={formValues.schooltel}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="chineseaddress"
                name="chineseaddress"
                label="Chinese Address"
                fullWidth
                value={formValues.chineseaddress}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="contactperson"
                name="contactperson"
                label="contactperson"
                fullWidth
                value={formValues.contactperson}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckConfirmed}
                    onChange={() => {
                      setIsCheckConfirmed(!isCheckConfirmed);
                    }}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    required
                  />
                }
                label="請確認資料"
              />
            </Grid>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="primary"
                onClick={submitForm}
                className={classes.button}
              >
                提交
              </Button>
            </div>
          </Paper>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserInfo;
