import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MessageIcon from "@material-ui/icons/Message";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { signOutUser } from "../../store/actions/user";

const MainListItems = () => {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const logOutUser = () => {
    dispatch(signOutUser());
  };
  return (
    <div>
      {!userState.isLoggedIn && (
        <ListItem button component={RouterLink} to="/login">
          <ListItemIcon>
            <LockOpenIcon />
          </ListItemIcon>
          <ListItemText primary="登入" />
        </ListItem>
      )}
      {userState.isLoggedIn && userState.userType === "Authenticated" && (
        <Fragment>
          <ListItem
            button
            component={RouterLink}
            to="/dashboard/message/addnew/personal"
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="主頁" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to="/form/returndate/addnew/personal"
          >
            <ListItemIcon>
              <ScheduleIcon />
            </ListItemIcon>
            <ListItemText primary="揀選收件日期" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to="/form/message/addnew/personal"
          >
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary="傳送訊息及檔案" />
          </ListItem>
        </Fragment>
      )}
      {userState.isLoggedIn && userState.userType === "CACLER" && (
        <Fragment>
          <ListItem
            button
            component={RouterLink}
            to="/dashboard/massmessage/addnew/personal"
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="通告列表" />
          </ListItem>
          <ListItem button component={RouterLink} to="/schoollist">
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="學校列表" />
          </ListItem>
          <ListItem button component={RouterLink} to="/chosendatelist">
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="揀選測試日期列表" />
          </ListItem>
          <ListItem button component={RouterLink} to="/returndatelist">
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="揀選回收日期列表" />
          </ListItem>

          <ListItem button component={RouterLink} to="/dateslist">
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Block Date 設定" />
          </ListItem>
          <ListItem
            button
            component={RouterLink}
            to="/form/massmessage/addnew/personal"
          >
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary="傳送通告(訊息及檔案)" />
          </ListItem>
          <ListItem button component={RouterLink} to="/importcsv/school">
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText primary="Import CSV for School (Franky Use)" />
          </ListItem>
        </Fragment>
      )}
      {userState.isLoggedIn && (
        <ListItem button onClick={logOutUser}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="登出" />
        </ListItem>
      )}
    </div>
  );
};

export default MainListItems;
