import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  loadingStyle: {
    marginTop: theme.spacing(5),
  },
}));

const MyLoadingSpinner = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.loadingStyle} />;
};

export default MyLoadingSpinner;
