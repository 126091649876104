import { useState, useEffect } from "react";
import axios from "axios";

const getBlockDates = (
  token,
  setIsLoading,
  setBlockDates,
  setBlockDatesArray
) => {
  setIsLoading(true);
  let i;
  let tempArray = [];
  axios
    .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/blockdates`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // Handle success.
      setBlockDates(response.data);

      for (i = 0; i < response.data.length; i++) {
        tempArray.push(response.data[i].blockdate);
      }

      setBlockDatesArray(tempArray);

      setIsLoading(false);
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
    });
};

export const useDatePicker = (token) => {
  const [blockDates, setBlockDates] = useState();
  const [blockDatesArray, setBlockDatesArray] = useState([]);
  const [dateIsLoading, setDateIsLoading] = useState(false);

  useEffect(() => {
    if (token) {
      getBlockDates(token, setDateIsLoading, setBlockDates, setBlockDatesArray);
    }
  }, [token, setDateIsLoading, setBlockDates, setBlockDatesArray]);

  const refreshBlockDates = (token) => {
    getBlockDates(token, setDateIsLoading, setBlockDates, setBlockDatesArray);
  };

  const addBlockDate = (token, selectedDate, refreshTable) => {
    setDateIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/blockdates`,
        {
          blockdate: selectedDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle success.
        setDateIsLoading(false);
        console.log("Data: ", response.data);
        refreshTable();
      })
      .catch((error) => {
        // Handle error.
        setDateIsLoading(false);
        console.log("An error occurred:", error.response);
      });
  };

  return {
    blockDates,
    blockDatesArray,
    dateIsLoading,
    refreshBlockDates,
    addBlockDate,
  };
};
