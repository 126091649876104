import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";

import MyLoadingSpinner from "../MyLoadingSpinner";

import TestDate from "./TestDate";
import ReturnDate from "./ReturnDate";

import { useSubmitForm } from "../../hooks/useSubmitForm";
import { useDialog } from "../../hooks/useDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const getChosenDate = (
  targetPath,
  targetId,
  token,
  setIsLoading,
  setPayload,
  setStatusRadio
) => {
  setIsLoading(true);

  axios
    .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/${targetPath}/${targetId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // Handle success.
      setIsLoading(false);
      setPayload(response.data);
      setStatusRadio(response.data.status);
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
      setIsLoading(false);
    });
};

const formOptions = {
  returndate: {
    targetPath: "returndates",
  },
  testdate: {
    targetPath: "reserve-test-dates",
  },
};

const StatusUpdate = () => {
  const classes = useStyles();
  let { formname, targetedId } = useParams();
  const userState = useSelector((state) => state.user);
  const [payload, setPayload] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [statusRadio, setStatusRadio] = useState("");
  const [isCheckConfirmed, setIsCheckConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);


  const { masterSubmitStatusUpdate } = useSubmitForm(userState.token);

  const { MyDialog, createDialog } = useDialog("statusupdate");

  const formComponents = {
    returndate: <ReturnDate payload={payload} />,
    testdate: <TestDate payload={payload} />,
  };

  const submitForm = () => {
    setErrorMessage("");
    if (!isCheckConfirmed) {
      setErrorMessage("請確認資料");
      return;
    }
    if (targetedId && isCheckConfirmed) {
      setIsSubmitDisable(true)
      let submitFormValues = {};
      submitFormValues = {
        status: statusRadio,
      };
      masterSubmitStatusUpdate(
        formOptions[formname].targetPath,
        targetedId,
        submitFormValues,
        createDialog
      );
    }
  };

  useEffect(() => {
    getChosenDate(
      formOptions[formname].targetPath,
      targetedId,
      userState.token,
      setIsLoading,
      setPayload,
      setStatusRadio
    );
  }, [formname, targetedId, userState.token]);

  return (
    <Fragment>
      {MyDialog}
      {isLoading ? (
        <MyLoadingSpinner />
      ) : (
        <Fragment>
          {formComponents[formname]}
          <FormLabel component="legend">狀態</FormLabel>
          <RadioGroup
            aria-label="status"
            name="status"
            value={statusRadio}
            onChange={(event) => {
              setStatusRadio(event.target.value);
            }}
          >
            <FormControlLabel
              value="Pending"
              control={<Radio />}
              label="等待確認"
            />
            <FormControlLabel
              value="Confirmed"
              control={<Radio />}
              label="已確認"
            />
            <FormControlLabel
              value="Cancelled"
              control={<Radio />}
              label="已取消"
            />
          </RadioGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCheckConfirmed}
                onChange={() => {
                  setIsCheckConfirmed(!isCheckConfirmed);
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
                required
              />
            }
            label="請確認資料"
          />
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={submitForm}
              className={classes.button}
              disabled={isSubmitDisable}
            >
              提交
            </Button>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default StatusUpdate;
