import React from "react";

import Typography from "@material-ui/core/Typography";

const SecondaryListItems = () => {
  return (
    <div style={{ padding: "15px" }}>
      <Typography variant="body1" gutterBottom>
        如有任何查詢，可致電 <br />
        <a href="tel:39175694">39175694</a> /
        <a href="tel:39168297"> 39168297</a> <br />
        與研究團隊聯絡，謝謝。
      </Typography>
    </div>
  );
};

export default SecondaryListItems;
