import React, { useEffect } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";

function App() {
  const history = useHistory();
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.push("/dashboard");
      }
    };
  }, [history]);

  return (
    <React.Fragment>
      <Router>
        <Dashboard></Dashboard>
      </Router>
    </React.Fragment>
  );
}

export default App;
