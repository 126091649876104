export const signInSuccess = (
  userId,
  email,
  token,
  schoolname,
  userType,
  schoolId,
  testclass
) => {
  return {
    type: "SIGN_IN_SUCCESS",
    payload: {
      userId,
      email,
      token,
      schoolname,
      userType,
      schoolId,
      testclass,
    },
  };
};

export const signOutUser = () => {
  return {
    type: "SIGN_OUT",
  };
};
