import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const Landing = () => {
  const userState = useSelector((state) => state.user);

  if (!userState.isLoggedIn) {
    return <Redirect to="/login" />;
  } else {
    if (userState.userType === "CACLER") {
      return <Redirect to="/dashboard/massmessage/addnew/personal" />;
    } else {
      return <Redirect to="/dashboard/message/addnew/personal" />;
    }
  }
};

export default Landing;
