import axios from "axios";

export const useSubmitForm = (token) => {
  const masterSubmitForm = (target, payload, setBackdropMessage) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/${target}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle success.
        console.log("Data: ", response.data);
        setBackdropMessage("success");
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        setBackdropMessage("failed");
      });
  };

  const masterSubmitUpdate = (
    target,
    targetId,
    payload,
    setBackdropMessage
  ) => {
    if (target === "massmessages") {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/${target}/${targetId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Handle success.
          console.log("Data: ", response.data);
          setBackdropMessage("success");
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error.response);
          setBackdropMessage("failed");
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/${target}/${targetId}`,
          { fieldstatus: "updated", status: "Updated" },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Handle success.

          setTimeout(() => {
            axios
              .post(
                `${process.env.REACT_APP_BACKEND_DOMAIN}/${target}`,
                payload,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                // Handle success.
                console.log("Data: ", response.data);
                setBackdropMessage("success");
              })
              .catch((error) => {
                // Handle error.
                console.log("An error occurred:", error.response);
                setBackdropMessage("failed");
              });
          }, 1000); //wait 1 seconds*/
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error.response);
          setBackdropMessage("failed");
        });
    }
  };

  const masterSubmitStatusUpdate = (
    target,
    targetId,
    payload,
    createDialog
  ) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/${target}/${targetId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle success.
        createDialog("success");
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        createDialog("failed");
      });
  };

  return {
    masterSubmitForm,
    masterSubmitUpdate,
    masterSubmitStatusUpdate,
  };
};
