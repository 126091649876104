import React, { Fragment, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTargetSchoolData } from "../../hooks/useTargetSchoolData";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Title from "../Title";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import TestDateHeader from "./TestDateHeader";

import MyFormContainer from "../MyForm/MyFormContainer";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 10,
    margin: theme.spacing(1),
  },
}));

const BoardHeader = (props) => {
  const classes = useStyles();
  const userState = useSelector((state) => state.user);
  const { activeReturnDateData, activeReserveDateData, targetedId } = props;

  const [isOpenMessage, setIsOpenMessage] = useState(false);

  const { schoolData, isLoading: schoolDataIsLoading } = useTargetSchoolData(
    userState.token,
    targetedId
  );

  return (
    <Fragment>
      {!schoolDataIsLoading && (
        <Fragment>
          <Grid item xs={12}>
            <Title>{schoolData.schoolName}</Title>
            <Typography component="p" variant="h4">
              {schoolData.schoolId}
            </Typography>
            {!(
              userState.userType === "CACLER" && targetedId === "personal"
            ) && (
              <Fragment>
                <TestDateHeader
                  targetUser={schoolData}
                  activeReserveDateData={activeReserveDateData}
                />
                <Grid item xs={12}>
                  <Typography component="p" variant="h6" display="inline">
                    回收日期:{" "}
                    {activeReturnDateData
                      ? activeReturnDateData.returndate
                      : "未設定"}
                  </Typography>
                  {activeReturnDateData ? (
                    <Fragment>
                      <Button
                        component={RouterLink}
                        to={`/form/returndate/update/${activeReturnDateData.id}`}
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        更改回收日期
                      </Button>
                      <Typography
                        component="p"
                        display="inline"
                        className={classes.spaceLeft}
                      >
                        <Link
                          href="https://bit.ly/3cG6QHG"
                          color="inherit"
                          target="_blank"
                          rel="noopener"
                        >
                          {"(使用說明 影片 / 長度：1:57 / 大小：7.9MB)"}
                        </Link>
                      </Typography>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {targetedId === "personal" ? (
                        <Button
                          component={RouterLink}
                          to="/form/returndate/addnew/personal"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          揀選回收日期
                        </Button>
                      ) : (
                        <Button
                          component={RouterLink}
                          to={"/form/returndate/addnew/" + targetedId}
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          Help 揀選回收日期
                        </Button>
                      )}
                      <Typography
                        component="p"
                        display="inline"
                        className={classes.spaceLeft}
                      >
                        <Link
                          href="https://bit.ly/3cG6QHG"
                          color="inherit"
                          target="_blank"
                          rel="noopener"
                        >
                          {"(使用說明 影片 / 長度：1:57 / 大小：7.9MB)"}
                        </Link>
                      </Typography>
                    </Fragment>
                  )}
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Fragment>
      )}
      {!isOpenMessage && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              setIsOpenMessage(true);
            }}
          >
            傳送訊息
          </Button>
        </Grid>
      )}

      {isOpenMessage && (
        <Fragment>
          <Grid item xs={12}>
            <MyFormContainer />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                setIsOpenMessage(false);
              }}
            >
              收起
            </Button>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default BoardHeader;
