import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const ReserveDate = (props) => {
  const { payload, statusChinese } = props;
  let history = useHistory();
  return (
    <Fragment>
      <Typography paragraph>學校聯絡主任名稱: {payload.teachername}</Typography>
      <Typography paragraph>電話: {payload.phonenumber}</Typography>
      <Typography paragraph>日期: {payload.returndate}</Typography>
      <Typography paragraph>狀態: {statusChinese}</Typography>
      {payload.fieldstatus !== "updated" && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            console.log(payload);
            history.push(`/form/returndate/update/${payload.id}`);
          }}
        >
          更改
        </Button>
      )}
    </Fragment>
  );
};

export default ReserveDate;
