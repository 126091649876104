import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const getData = async (token, target) => {
  //setIsLoading(true);
  let response = await axios.get(
    `${process.env.REACT_APP_BACKEND_DOMAIN}/${target}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await response.data;
};

const compare = (a, b) => {
  if (a.updated_at > b.updated_at) {
    return -1;
  }
  if (a.updated_at < b.updated_at) {
    return 1;
  }
  return 0;
};

const compareInternalId = (a, b) => {
  if (a.internalid < b.internalid) {
    return -1;
  }
  if (a.internalid > b.internalid) {
    return 1;
  }
  return 0;
};

const processActiveDateData = (theData, setFunction) => {
  for (let i = 0; i < theData.length; i++) {
    if (theData[i].fieldstatus === "active") {
      setFunction(theData[i]);
    }
  }
};

const processActiveDateArrayData = (theData, setFunction) => {
  let tempArray = [];
  for (let i = 0; i < theData.length; i++) {
    if (theData[i].fieldstatus === "active") {
      tempArray.push(theData[i]);
    }
  }
  tempArray = tempArray.sort(compareInternalId);
  setFunction(tempArray);
};

const getIndividualData = async (token, targetId, targetPath) => {
  let payload;
  if (targetPath === "massmessages") {
    payload = await getData(token, targetPath);
  } else {
    if (targetId === "personal") {
      payload = await getData(token, `${targetPath}/mine`);
    } else {
      payload = await getData(token, `${targetPath}/findOfSchool/${targetId}`);
    }
  }

  return payload;
};

const getTestDateVersionData = async (
  token,
  targetId,
  setIsLoading,
  setActiveReserveDateData
) => {
  setIsLoading(true);
  let reserveData = {};

  reserveData = await getIndividualData(token, targetId, "reserve-test-dates");

  processActiveDateArrayData(reserveData, setActiveReserveDateData);

  setIsLoading(false);
};

export const useSchoolData = (token, dataVersion = "full") => {
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState([]);

  const [activeReturnDateData, setActiveReturnDateData] = useState({});
  const [activeReserveDateData, setActiveReserveDateData] = useState([]);
  let { targetedId } = useParams();

  const getPersonalData = useCallback(
    async (targetId) => {
      setIsLoading(true);

      let messageData = {};
      let reserveData = {};
      let massMessageData = {};
      let returnDateData = {};

      messageData = await getIndividualData(token, targetId, "messages");
      reserveData = await getIndividualData(
        token,
        targetId,
        "reserve-test-dates"
      );
      returnDateData = await getIndividualData(token, targetId, "returndates");
      massMessageData = await getIndividualData(
        token,
        targetId,
        "massmessages"
      );

      processActiveDateArrayData(reserveData, setActiveReserveDateData);
      processActiveDateData(returnDateData, setActiveReturnDateData);
      let payloadArray = [
        ...messageData,
        ...reserveData,
        ...returnDateData,
        ...massMessageData,
      ];
      payloadArray = payloadArray.sort(compare);
      setPayload(payloadArray);
      setIsLoading(false);
    },
    [token]
  );

  useEffect(() => {
    if (token) {
      if (dataVersion === "full") {
        setIsLoading(true);
        setActiveReturnDateData(false);
        setActiveReserveDateData(false);
        getPersonalData(targetedId);
      } else if (dataVersion === "testdate") {
        //to do
        setIsLoading(true);
        setActiveReturnDateData(false);
        //get set test date
        getTestDateVersionData(
          token,
          targetedId,
          setIsLoading,
          setActiveReserveDateData
        );
      }
    }
  }, [token, getPersonalData, targetedId, dataVersion]);

  return {
    payload,
    dataIsLoading: isLoading,
    activeReturnDateData,
    activeReserveDateData,
    targetedId,
  };
};
