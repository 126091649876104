import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Button from "@material-ui/core/Button";

import { useDatePicker } from "../hooks/useDatepicker";

import MyDatePicker from "../components/MyDatePicker";
import MyLoadingSpinner from "../components/MyLoadingSpinner";
import MyContainer from "../components/MyContainer";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Orders() {
  const classes = useStyles();
  const userState = useSelector((state) => state.user);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const {
    blockDates,
    blockDatesArray,
    dateIsLoading,
    refreshBlockDates,
    addBlockDate,
  } = useDatePicker(userState.token);

  if (!userState.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const refreshTable = () => {
    refreshBlockDates(userState.token);
  };

  const submitForm = () => {
    addBlockDate(userState.token, selectedDate, refreshTable);
  };

  const columns = [
    {
      title: "id",
      field: "id",
    },
    {
      title: "blockdate",
      field: "blockdate",
    },
  ];
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          refreshTable();
        }}
      >
        Refresh
      </Button>
      {dateIsLoading ? (
        <MyLoadingSpinner />
      ) : (
        <React.Fragment>
          <div style={{ maxwidth: "100%" }}>
            <MaterialTable
              icons={tableIcons}
              title="Blocked Dates"
              data={blockDates}
              columns={columns}
              options={{
                search: true,
                paging: false,
                filtering: true,
                exportButton: true,
              }}
            />
          </div>

          <MyContainer>
            <Typography component="h1" variant="h5">
              Block Date
            </Typography>
            <MyDatePicker
              blockDatesArray={blockDatesArray}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <form className={classes.form} noValidate>
              <Button
                onClick={() => {
                  submitForm();
                }}
                //type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </MyContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
