import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

export const useUpdateFormData = (token) => {
  const [isLoading, setIsLoading] = useState(true);
  const [payload, setPayload] = useState(false);

  const { formname, method, targetedId } = useParams();

  const getData = useCallback(
    (targetPath) => {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}${targetPath}/${targetedId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // Handle success.
          setPayload(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          // Handle error.
          console.log("An error occurred:", error.response);
          setIsLoading(false);
        });
    },
    [targetedId, token]
  );

  useEffect(() => {
    setIsLoading(true);
    if (method === "update" && targetedId) {
      if (formname === "returndate") {
        getData("/returndates");
      } else if (formname === "testdate") {
        getData("/reserve-test-dates");
      } else if (formname === "massmessage") {
        getData("/massmessages");
      }
    } else {
      setIsLoading(false);
    }
  }, [formname, method, targetedId, getData]);

  return {
    isLoading,
    payload,
  };
};
