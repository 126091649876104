import { Fragment } from "react";

import Typography from "@material-ui/core/Typography";

const TestDate = (props) => {
  const { payload } = props;

  console.log(payload);

  return (
    <Fragment>
      <Typography component="h1" variant="h4" align="center">
        回收日期: {payload.selectedTestDate}
      </Typography>
      <Typography paragraph>學校聯絡主任姓名: {payload.teachername}</Typography>
      <Typography paragraph>電話: {payload.phonenumber}</Typography>
      <Typography paragraph>日期: {payload.returndate}</Typography>
      <Typography paragraph>
        狀態: {payload.status === "Pending" && "等待確認"}
        {payload.status === "Confirmed" && "已確認"}
        {payload.status === "Cancelled" && "已取消"}
      </Typography>
    </Fragment>
  );
};

export default TestDate;
