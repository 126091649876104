import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MyDatePicker from "../MyDatePicker";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  innerPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paddingTop: {
    padding: theme.spacing(2),
  },
}));

const initialFormValues = {
  teachername: "",
  phonenumber: "",
};

const DateFrom = (props) => {
  const classes = useStyles();

  const { containerSubmitForm, payload } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [formValues, setFormValues] = useState(initialFormValues);
  const [isCheckConfirmed, setIsCheckConfirmed] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);

  useEffect(() => {
    if (payload) {
      setFormValues(payload);
      setSelectedDate(payload.returndate);
    }
  }, [payload]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const submitForm = () => {
    setErrorMessage(null);
    if (!isCheckConfirmed) {
      setErrorMessage("請確認資料");
      return;
    }

    if (formValues.teachername === "" || formValues.phonenumber === "") {
      setErrorMessage("請填寫資料");
      return;
    }

    let submitFormValues = {};
    submitFormValues = {
      ...formValues,
      returndate: selectedDate,
    };
    setIsSubmitDisable(true);
    containerSubmitForm(submitFormValues, "returndates");
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          required
          id="teachername"
          name="teachername"
          label="學校聯絡主任姓名"
          fullWidth
          autoComplete="teacher-name"
          value={formValues.teachername}
          onChange={handleInputChange}
          error={!!(errorMessage && formValues.teachername === "")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="phonenumber"
          name="phonenumber"
          label="電話"
          fullWidth
          autoComplete="class-name"
          value={formValues.phonenumber}
          onChange={handleInputChange}
          error={!!(errorMessage && formValues.phonenumber === "")}
        />
      </Grid>
      <Paper className={classes.innerPaper} elevation={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              請於下列日期或之後派員到本校收回所有文件：
            </Typography>
            <Typography variant="body1" gutterBottom>
              日期
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MyDatePicker
              blockDatesArray={[]}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.innerPaper} elevation={5}>
        <Typography variant="body1" gutterBottom>
          多謝 貴校參與是次研究，若有任何疑問，歡迎聯絡3917 8297 (林小姐) 或
          3917 5694 (吳博士)，謝謝。
        </Typography>
      </Paper>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckConfirmed}
              onChange={() => {
                setIsCheckConfirmed(!isCheckConfirmed);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
              required
            />
          }
          label="請確認資料"
        />
      </Grid>

      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          onClick={submitForm}
          className={classes.button}
          disabled={isSubmitDisable}
        >
          提交
        </Button>
      </div>
    </React.Fragment>
  );
};

export default DateFrom;
