import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { red } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FaceIcon from "@material-ui/icons/Face";
import Avatar from "@material-ui/core/Avatar";
import EventIcon from "@material-ui/icons/Event";
import EditIcon from "@material-ui/icons/Edit";

import PirlsLogo from "../../assets/pirls2021.png";

import Message from "./Message";
import ReserveDate from "./ReserveDate";
import ReturnDate from "./ReturnDate";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 345,
    margin: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardNormal: {
    color: "black",
  },
  cardUpdated: {
    color: "grey",
  },
}));

const MessageDetail = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory();
  const userState = useSelector((state) => state.user);

  const { payload } = props;

  let statusTitle = "";
  let selectedAvatar = "";

  let statusChinese = "";
  if (payload.status === "Pending") {
    statusChinese = "等待確認";
  } else if (payload.status === "Confirmed") {
    statusChinese = "已確認";
  } else if (payload.status === "Cancelled") {
    statusChinese = "已取消";
  } else if (payload.status === "Updated") {
    statusChinese = "已更新";
  }

  if (payload.type === "reserveTestDate") {
    selectedAvatar = <EventIcon fontSize="large" />;

    statusTitle =
      "揀選測試日期: (" +
      statusChinese +
      ") " +
      payload.targetedClassName +
      " (" +
      payload.selectedTestDate +
      ")";
  } else if (payload.type === "returndate") {
    selectedAvatar = <EventIcon fontSize="large" />;

    statusTitle =
      "揀選收件日期: (" +
      statusChinese +
      ") " +
      " (" +
      payload.returndate +
      ")";
  } else if (payload.type === "message") {
    if (payload.isCacler) {
      selectedAvatar = (
        <Avatar variant="square" alt="PIRLS Logo" src={PirlsLogo} />
      );
      statusTitle = "訊息及檔案: " + payload.title;
    } else {
      selectedAvatar = <FaceIcon fontSize="large" />;
      statusTitle = "訊息及檔案(上載成功): " + payload.title;
    }
  } else if (payload.type === "massmessage") {
    selectedAvatar = (
      <Avatar variant="square" alt="PIRLS Logo" src={PirlsLogo} />
    );
    statusTitle = "通告(訊息及檔案): " + payload.title;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let cardColor = "normal";
  if (payload.fieldstatus === "updated") {
    cardColor = classes.cardUpdated;
  } else {
    cardColor = classes.cardNormal;
  }

  return (
    <Fragment>
      {payload.type === "massmessage" && payload.fieldstatus === "updated" ? (
        <Fragment></Fragment>
      ) : (
        <Card className={classes.root} elevation={5}>
          <CardHeader
            avatar={selectedAvatar}
            title={statusTitle}
            subheader={`更新時間: ${new Date(payload.updated_at)}`}
            titleTypographyProps={{ variant: "h4" }}
            subheaderTypographyProps={{ variant: "subtitle1" }}
            className={cardColor}
          />
          <CardActions disableSpacing>
            {userState.userType === "CACLER" && payload.type === "massmessage" && (
              <IconButton
                aria-label="share"
                onClick={() => {
                  history.push(`/form/massmessage/update/${payload.id}`);
                }}
              >
                <EditIcon />
              </IconButton>
            )}

            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              展開內容
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={cardColor}>
              {payload.type === "reserveTestDate" && (
                <ReserveDate payload={payload} statusChinese={statusChinese} />
              )}
              {payload.type === "returndate" && (
                <ReturnDate payload={payload} statusChinese={statusChinese} />
              )}
              {payload.type === "message" && (
                <Message payload={payload} statusChinese={statusChinese} />
              )}
              {payload.type === "massmessage" && (
                <Message payload={payload} statusChinese={statusChinese} />
              )}
            </CardContent>
          </Collapse>
        </Card>
      )}
    </Fragment>
  );
};

export default MessageDetail;
