import React, { useState, useEffect } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const ClassInfoDialog = (props) => {
  const {
    isDialogOpen,
    handleClose,
    formValues,
    classIndex,
    setFormValues,
  } = props;

  const [classid, setClassid] = useState("");
  const [classname, setClassname] = useState("");

  const handleUpdate = () => {
    if (classIndex === "addnew") {
      let tempClassValues = {
        classname: classname,
        classid: classid,
      };
      let tempAllClassValues = formValues.testclass;
      tempAllClassValues.push(tempClassValues);
      console.log(tempAllClassValues);
      setFormValues({ ...formValues, testclass: tempAllClassValues });
      handleClose();
    } else {
      let tempClassValues = formValues.testclass[classIndex];
      console.log(tempClassValues);
      tempClassValues = {
        ...tempClassValues,
        classname: classname,
        classid: classid,
      };
      console.log(tempClassValues);

      let tempAllClassValues = formValues.testclass;
      tempAllClassValues[classIndex] = tempClassValues;
      console.log(tempAllClassValues);
      setFormValues({ ...formValues, testclass: tempAllClassValues });
      handleClose();
    }
  };

  useEffect(() => {
    if (classIndex !== "addnew") {
      if (formValues.testclass) {
        setClassid(formValues.testclass[classIndex].classid);
        setClassname(formValues.testclass[classIndex].classname);
      }
    } else {
      setClassid("");
      setClassname("");
    }
  }, [classIndex, formValues]);
  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Edit Class ID: ({classIndex})
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="classid"
          label="Class ID"
          value={classid}
          fullWidth
          onChange={(e) => {
            setClassid(e.target.value);
          }}
        />
        <TextField
          autoFocus
          margin="dense"
          id="classname"
          label="Class Name"
          value={classname}
          fullWidth
          onChange={(e) => {
            setClassname(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClassInfoDialog;
