import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  innerPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paddingTop: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(3),
  },
  spaceLeft: {
    paddingLeft: theme.spacing(1),
  },
}));

const TestDateHeader = (props) => {
  const classes = useStyles();

  const { targetUser, activeReserveDateData, setIsLoading, chooseForm } = props;
  const { targetedId } = useParams();
  const history = useHistory();

  return (
    <Fragment>
      {chooseForm ? (
        <Fragment>
          {targetUser.testclass.map((item, index) => {
            return (
              <Fragment key={index}>
                <Paper className={classes.innerPaper} elevation={5}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        {item.classname}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        學校編號: {item.classid}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        測試日期:{" "}
                        {activeReserveDateData[index]
                          ? activeReserveDateData[index].selectedTestDate
                          : "未設定"}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        問卷日期:{" "}
                        {activeReserveDateData[index]
                          ? activeReserveDateData[index].selectedSurveyDate
                          : "未設定"}
                      </Typography>
                      {activeReserveDateData[index] ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setIsLoading(true);
                            history.push(
                              `/form/testdate/update/${activeReserveDateData[index].id}`
                            );
                          }}
                        >
                          更新
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            chooseForm(index);
                          }}
                        >
                          新增
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Fragment>
            );
          })}
        </Fragment>
      ) : (
        <Fragment>
          <Grid item xs={12}>
            <Typography component="p" variant="h6" display="inline">
              測試日期:{" "}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/form/testdate/addnew/${targetedId}`);
              }}
            >
              設定測驗日期
            </Button>
            <Typography
              component="p"
              display="inline"
              className={classes.spaceLeft}
            >
              <Link
                href="https://bit.ly/2QK11Ae"
                color="inherit"
                target="_blank"
                rel="noopener"
              >
                {"(使用說明 影片 / 長度：4:04 / 大小：15.1MB)"}
              </Link>
            </Typography>
          </Grid>

          {targetUser.testclass &&
            targetUser.testclass.map((item, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Paper className={classes.innerPaper} elevation={5}>
                    <Typography variant="h6" gutterBottom>
                      {item.classname}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      學校編號: {item.classid}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      測試日期:{" "}
                      {activeReserveDateData[index]
                        ? activeReserveDateData[index].selectedTestDate
                        : "未設定"}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      問卷日期:{" "}
                      {activeReserveDateData[index]
                        ? activeReserveDateData[index].selectedSurveyDate
                        : "未設定"}
                    </Typography>
                  </Paper>
                </Grid>
              );
            })}
        </Fragment>
      )}
    </Fragment>
  );
};

export default TestDateHeader;
