import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

export const useTargetSchoolData = (token) => {
  const userState = useSelector((state) => state.user);
  const { formname, targetedId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [schoolData, setSchoolData] = useState({});

  const getSchoolData = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/users/${targetedId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle success.
        setSchoolData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        setIsLoading(false);
      });
  }, [targetedId, token, setIsLoading]);

  useEffect(() => {
    setIsLoading(true);

    if (targetedId !== "personal") {
      getSchoolData();
    } else {
      setSchoolData(userState);
      setIsLoading(false);
    }
  }, [targetedId, formname, getSchoolData, userState]);

  return {
    isLoading,
    schoolData,
  };
};
