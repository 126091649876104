import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import BoardHeader from "../components/NoticeBoard/BoardHeader";

import { useSchoolData } from "../hooks/useSchoolData";

import MessageDetail from "../components/MessageDetail/MessageDetail";
import MyLoadingSpinner from "../components/MyLoadingSpinner";

export default function Home() {
  const userState = useSelector((state) => state.user);

  const {
    payload,
    activeReturnDateData,
    activeReserveDateData,
    targetedId,
    dataIsLoading,
  } = useSchoolData(userState.token);

  if (!userState.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        {!dataIsLoading && (
          <BoardHeader
            activeReturnDateData={activeReturnDateData}
            activeReserveDateData={activeReserveDateData}
            targetedId={targetedId}
            dataIsLoading={dataIsLoading}
          />
        )}
      </Grid>
      <Fragment>
        {dataIsLoading ? (
          <MyLoadingSpinner />
        ) : (
          <Fragment>
            {payload.map((item, index) => {
              return <MessageDetail payload={item} key={index} />;
            })}
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  );
}
