import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import MyLoadingSpinner from "../components/MyLoadingSpinner";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function SchoolList() {
  const userState = useSelector((state) => state.user);
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [schoolsData, setSchoolsData] = useState([]);

  const [dataViewType, setDataViewType] = useState('default');
  const [outPutData, setOutPutData] = useState([])

  useEffect(() => {
    getUsers(userState.token);
  }, [userState.token]);

  const getUsers = (token) => {
    let tempArray = [];
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/users?_limit=-1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle success.
        //setSchoolsData(response.data);

        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].schoolName === "CACLER") {
            continue;
          }
          let temptestclass = '';
          let tempeallmail = '';
          response.data[i].testclass.map((item, index) => {
            temptestclass += '<' + item.classname + '>';
            return true;
          })
          response.data[i].allemail.map((item, index) => {
            tempeallmail += '<' + item.emailaddress + '>';
            return true;
          })

          tempArray.push({
            id: response.data[i].id,
            username: response.data[i].username,
            schoolname: response.data[i].schoolName,
            defaultemail: response.data[i].email,
            schooladdress: response.data[i].chineseaddress,
            contactperson: response.data[i].contactperson,
            contacttel: response.data[i].contacttel,
            schooltel: response.data[i].schooltel,
            testclass: temptestclass,
            allemail: tempeallmail,
          });
        }

        setSchoolsData(tempArray);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error.
        setIsLoading(false);
        console.log("An error occurred:", error.response);
      });
  };

  const refreshTable = () => {
    getUsers(userState.token);
  };

  useEffect(() => {
    let tempoutputArray = []
    if (dataViewType === "default") {
      tempoutputArray = [
        {
          title: "學校編號",
          field: "username",
        },
        {
          title: "學校名稱",
          field: "schoolname",
        },
        {
          title: "Target",
          field: "url",
          render: (rowData) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/userinfo/${rowData.id}`);
              }}
            >
              School Info
            </Button>
          ),
        },
        {
          title: "Target",
          field: "url",
          render: (rowData) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/dashboard/message/addnew/${rowData.id}`);
              }}
            >
              See School Dashboard
            </Button>
          ),
        },
      ];
      setOutPutData(tempoutputArray);
    } else if (dataViewType === "export") {
      tempoutputArray = [
        {
          title: "學校編號",
          field: "username",
        },
        {
          title: "學校名稱",
          field: "schoolname",
        },
        {
          title: "學校Email",
          field: "defaultemail",
        },
        {
          title: "School Address",
          field: "schooladdress",
        },
        {
          title: "School Tel",
          field: "schooltel",
        },
        {
          title: "Contact Tel",
          field: "contacttel",
        },
        {
          title: "Class",
          field: "testclass",
        },
        {
          title: "All Email",
          field: "allemail",
        },
      ];
      setOutPutData(tempoutputArray)
    } else if (dataViewType === "actions") {
      tempoutputArray = [
        {
          title: "學校編號",
          field: "username",
        },
        {
          title: "學校名稱",
          field: "schoolname",
        },
        {
          title: "直接訊息",
          field: "url",
          render: (rowData) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/form/message/addnew/${rowData.id}`);
              }}
            >
              Direct Message
            </Button>
          ),
        },
        {
          title: "Target",
          field: "url",
          render: (rowData) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/form/testdate/addnew/${rowData.id}`);
              }}
            >
              Choose date
            </Button>
          ),
        },
        {
          title: "Target",
          field: "url",
          render: (rowData) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/form/returndate/addnew/${rowData.id}`);
              }}
            >
              Return date
            </Button>
          ),
        },
        {
          title: "Target",
          field: "url",
          render: (rowData) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/userinfo/${rowData.id}`);
              }}
            >
              School Info
            </Button>
          ),
        },
        {
          title: "Target",
          field: "url",
          render: (rowData) => (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/dashboard/message/addnew/${rowData.id}`);
              }}
            >
              See School Dashboard
            </Button>
          ),
        },
      ];
      setOutPutData(tempoutputArray)
    }
  }, [dataViewType, history])

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          refreshTable();
        }}
      >
        Refresh
      </Button>
      <RadioGroup
        aria-label="status"
        name="status"
        value={dataViewType}
        onChange={(event) => {
          setDataViewType(event.target.value);
        }}
      >
        <FormControlLabel
          value="default"
          control={<Radio />}
          label="Default"
        />
        <FormControlLabel
          value="export"
          control={<Radio />}
          label="Export All"
        />
        <FormControlLabel
          value="actions"
          control={<Radio />}
          label="Actions"
        />
      </RadioGroup>
      {isLoading ? (
        <MyLoadingSpinner />
      ) : (
        <React.Fragment>
          <div style={{ maxwidth: "100%" }}>
            <MaterialTable
              icons={tableIcons}
              title="學校列表"
              data={schoolsData}
              columns={outPutData}
              options={{
                search: true,
                paging: false,
                filtering: true,
                exportButton: true,
              }}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
