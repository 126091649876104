import React, { useState, useCallback, useEffect, Fragment } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { signInSuccess } from "../store/actions/user";
import MyContainer from "../components/MyContainer";
import { Redirect } from "react-router-dom";

import MyLoadingSpinner from "../components/MyLoadingSpinner";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  eye: {
    cursor: "pointer",
  },
}));

const Login = () => {
  const userState = useSelector((state) => state.user);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [version, setVersion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordMask, setPasswordMask] = useState(true);

  const togglePasswordMask = () => {
    setPasswordMask((prevState) => {
      return !prevState;
    });
  };

  const getVersion = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_DOMAIN}/version`)
      .then((response) => {
        // Handle success.
        console.log(response.data);
        setVersion(response.data.versionid);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error.
        console.log("An error occurred:", error.response);
        setIsLoading(false);
      });
  }, [setIsLoading]);

  useEffect(() => {
    getVersion();
  }, [getVersion]);

  if (userState.isLoggedIn) {
    return <Redirect to="/" />;
  }

  const signIn = () => {
    if (!email || !password) {
      setErrorMessage("Please enter email and password");
      return;
    }
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/auth/local`, {
        identifier: email,
        password: password,
      })
      .then((response) => {
        // Handle success.
        dispatch(
          signInSuccess(
            response.data.user.id,
            response.data.user.email,
            response.data.jwt,
            response.data.user.schoolName,
            response.data.user.role.name,
            response.data.user.schoolId,
            response.data.user.testclass
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle error.
        //wrong password here
        console.log("An error occurred:", error.response);
        setErrorMessage("Email or password invalid");
        setIsLoading(false);
      });
  };

  return (
    <MyContainer>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        登入
      </Typography>
      <Typography component="p" style={{ color: "red" }}>
        {errorMessage}
      </Typography>
      {isLoading ? (
        <MyLoadingSpinner />
      ) : (
        <form className={classes.form} noValidate onSubmit={signIn}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="學校ID"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="密碼"
            type={passwordMask ? "password" : "text"}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <RemoveRedEye
                    onClick={togglePasswordMask}
                    className={classes.eye}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Button
            //type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            登入
          </Button>
          <Typography variant="body1" gutterBottom>
            版本: {process.env.REACT_APP_VERSION}{" "}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {version === process.env.REACT_APP_VERSION ? (
              `您正使用最新的版本:(${version})`
            ) : (
              <Fragment>
                有更新版本:({version}){" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.location.reload(true)}
                >
                  更新
                </Button>
              </Fragment>
            )}
          </Typography>
        </form>
      )}
    </MyContainer>
  );
};

export default Login;
