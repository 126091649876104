import React, { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import MyLoadingSpinner from "../MyLoadingSpinner";
import { useDialog } from "../../hooks/useDialog";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ReturnDateForm from "./ReturnDateForm";
import TestDateForm from "./TestDateForm";
import MessageForm from "./MessageForm";

import { useSubmitForm } from "../../hooks/useSubmitForm";
import { useTargetSchoolData } from "../../hooks/useTargetSchoolData";
import { useUpdateFormData } from "../../hooks/useUpdateFormData";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  innerPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paddingTop: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paddingX: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const MyFormContainer = () => {
  const classes = useStyles();

  const userState = useSelector((state) => state.user);
  let { formname, method, targetedId } = useParams();

  const { MyDialog, createDialog } = useDialog("form", targetedId);
  const [isLoading, setIsLoading] = useState(false);

  const { masterSubmitForm, masterSubmitUpdate } = useSubmitForm(
    userState.token
  );

  const formOptions = {
    returndate: {
      title: "要求收件回條",
    },
    testdate: {
      title: "測試日期和時間",
    },
    message: {
      title: "訊息及檔案",
    },
    massmessage: {
      title: "通告 (訊息及檔案)",
    },
  };

  const { schoolData, isLoading: schoolDataIsLoading } = useTargetSchoolData(
    userState.token,
    targetedId
  );
  const { payload, isLoading: formDataIsLoading } = useUpdateFormData(
    userState.token
  );

  const submitForm = (formValues, targetPath, formData) => {
    let submitFormValues = {};

    if (method === "addnew") {
      if (targetedId === "personal") {
        submitFormValues = {
          ...formValues,
          users_permissions_user: userState.userId,
        };
        if (formData) {
          formData.append("data", JSON.stringify(submitFormValues));
          masterSubmitForm(targetPath, formData, createDialog);
        } else {
          masterSubmitForm(targetPath, submitFormValues, createDialog);
        }
      } else {
        submitFormValues = {
          ...formValues,
          users_permissions_user: targetedId,
          status: "Confirmed",
          isCacler: true,
        };
        if (formData) {
          formData.append("data", JSON.stringify(submitFormValues));
          masterSubmitForm(targetPath, formData, createDialog);
        } else {
          masterSubmitForm(targetPath, submitFormValues, createDialog);
        }
      }
    } else if (method === "update") {
      //change old entry status to updated
      if (userState.userType === "CACLER") {
        if (formname === "massmessage") {
          submitFormValues = {
            ...formValues,
          };
        } else {
          submitFormValues = {
            ...formValues,
            status: "Confirmed",
            users_permissions_user: payload.users_permissions_user.id,
          };
        }
      } else {
        submitFormValues = {
          ...formValues,
          status: "Pending",
          users_permissions_user: userState.userId,
        };
      }

      masterSubmitUpdate(
        targetPath,
        targetedId,
        submitFormValues,
        createDialog
      );
      //add new entry
    }
  };

  const formComponents = {
    returndate: (
      <ReturnDateForm containerSubmitForm={submitForm} payload={payload} />
    ),
    testdate: (
      <TestDateForm
        setIsLoading={setIsLoading}
        containerSubmitForm={submitForm}
        payload={payload}
        schoolData={schoolData}
      />
    ),
    message: <MessageForm containerSubmitForm={submitForm} payload={payload} />,
    massmessage: (
      <MessageForm containerSubmitForm={submitForm} payload={payload} />
    ),
  };

  useEffect(() => {
    if (formDataIsLoading === false && schoolDataIsLoading === false) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [formDataIsLoading, schoolDataIsLoading]);

  if (!userState.isLoggedIn) {
    return <Redirect to={"/login"} />;
  }

  return (
    <Fragment>
      {MyDialog}
      {isLoading || schoolDataIsLoading || formDataIsLoading ? (
        <MyLoadingSpinner />
      ) : (
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              {formOptions[formname].title}
            </Typography>
            <Grid container spacing={3}>
              {method === "addnew" &&
                userState.userType === "CACLER" &&
                formname === "massmessage" && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingTop}
                    >
                      傳送者/選擇者: {userState.schoolId}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      學校名稱: All
                    </Typography>
                  </Grid>
                )}
              {method === "addnew" &&
                userState.userType === "CACLER" &&
                formname !== "massmessage" && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingTop}
                    >
                      傳送者/選擇者: {userState.schoolId}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      target 學校名稱: {schoolData.schoolName}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      學校編號: {schoolData.schoolId}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      School Address: {schoolData.chineseaddress}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      Contact Person: {schoolData.contactperson}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      School Tel: {schoolData.schooltel}
                    </Typography>
                  </Grid>
                )}
              {method === "update" &&
                userState.userType === "CACLER" &&
                formname !== "massmessage" && (
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingTop}
                    >
                      傳送者/選擇者: {userState.schoolId}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      target 學校名稱:{" "}
                      {payload.users_permissions_user.schoolName}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      學校編號: {payload.users_permissions_user.schoolId}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      School Address:{" "}
                      {payload.users_permissions_user.chineseaddress}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      Contact Person:{" "}
                      {payload.users_permissions_user.contactperson}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.paddingX}
                    >
                      School Tel: {payload.users_permissions_user.schooltel}
                    </Typography>
                  </Grid>
                )}
              {userState.userType === "Authenticated" && (
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.paddingTop}
                  >
                    學校名稱:{userState.schoolName}
                  </Typography>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.paddingTop}
                  >
                    學校編號:{userState.schoolId}
                  </Typography>
                </Grid>
              )}
              {formComponents[formname]}
            </Grid>
          </Paper>
        </main>
      )}
    </Fragment>
  );
};

export default MyFormContainer;
