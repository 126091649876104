import React, { useState, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

export const useDialog = (groupname) => {
  const history = useHistory();
  const { formname } = useParams();
  const [open, setOpen] = useState(false);

  const [message, setMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const createDialog = (message) => {
    setMessage(message);
    setOpen(true);
  };

  const handleSucessClick = () => {
    setMessage("");
    history.push("/");
  };

  const handleBackToSame = () => {
    setMessage("");
    if (groupname === "statusupdate") {
      if (formname === "testdate") {
        history.push("/chosendatelist");
      } else if (formname === "returndate") {
        history.push("/returndatelist");
      }
    } else if (groupname === "useinfoupdate") {
      history.push("/schoollist");
    } else {
      history.push("/");
    }
  };

  const MyDialog = (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {message === "success" ? (
        <Fragment>
          <DialogTitle id="alert-dialog-title">提交成功</DialogTitle>
          <DialogActions>
            <Button onClick={handleSucessClick} color="primary">
              返回主頁
            </Button>
            {!(groupname === "form") && (
              <Button onClick={handleBackToSame} color="primary">
                返回列表
              </Button>
            )}
          </DialogActions>
        </Fragment>
      ) : (
        <Fragment>
          <DialogTitle id="alert-dialog-title">
            提交失敗，請再試一次
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              確定
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
  return {
    createDialog,
    MyDialog,
  };
};
