import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DropzoneArea } from "material-ui-dropzone";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  innerPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paddingTop: {
    padding: theme.spacing(2),
  },
}));

const initialFormValues = {
  title: "",
  message: "",
};

const MessageFrom = (props) => {
  const classes = useStyles();

  const { containerSubmitForm, payload } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState(initialFormValues);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isCheckConfirmed, setIsCheckConfirmed] = useState(false);
  const { formname } = useParams();
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);

  useEffect(() => {
    if (payload) {
      setFormValues(payload);
      //setSelectedDate(payload.returndate);
    }
  }, [payload]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const submitForm = () => {
    setErrorMessage(null);
    if (!isCheckConfirmed) {
      setErrorMessage("請確認資料");
      return;
    }

    if (formValues.title === "" || formValues.message === "") {
      setErrorMessage("請填寫資料");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append(
        `files.uploadfiles`,
        filesToUpload[i],
        filesToUpload[i].name
      );
    }
    setIsSubmitDisable(true);

    if (formname === "message") {
      containerSubmitForm(formValues, "messages", formData);
    } else if (formname === "massmessage") {
      containerSubmitForm(formValues, "massmessages", formData);
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <TextField
          required
          id="title"
          name="title"
          label="標題"
          fullWidth
          value={formValues.title}
          onChange={handleInputChange}
          error={!!(errorMessage && formValues.title === "")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="message"
          name="message"
          label="訊息"
          fullWidth
          value={formValues.message}
          onChange={handleInputChange}
          error={!!(errorMessage && formValues.message === "")}
          multiline={true}
          rows={10}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <DropzoneArea
          filesLimit={5}
          dropzoneText="按此處上載文件(最多5個)"
          onChange={(files) => {
            setFilesToUpload(files);
          }}
          showFileNames={true}
          acceptedFiles={[
            "image/*",
            ".docx",
            ".doc",
            ".pdf",
            ".xlsx",
            ".xls",
            ".csv",
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckConfirmed}
              onChange={() => {
                setIsCheckConfirmed(!isCheckConfirmed);
              }}
              inputProps={{ "aria-label": "primary checkbox" }}
              required
            />
          }
          label="請確認資料"
        />
      </Grid>

      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          onClick={submitForm}
          className={classes.button}
          disabled={isSubmitDisable}
        >
          提交
        </Button>
      </div>
    </React.Fragment>
  );
};

export default MessageFrom;
